import React, { FunctionComponent, useEffect, useState } from "react"

interface Props {
  className?: string
  loop?: boolean
  text: string
}

const TypedText: FunctionComponent<Props> = ({
  className,
  loop = true,
  text,
  delay,
}) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (loop) {
      interval = setInterval(
        () => {
          setIndex(i => {
            if (i === text.length) {
              return text.length - 2
            }

            return i + 1
          })
        },
        delay ? delay : 200
      )
    } else {
      for (let i = 0; i < text.length; i += 1) {
        setTimeout(() => setIndex(i + 1), 200 * i)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [text, loop])

  return (
    <div className={className}>
      {text.split("").map((elem, elemIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <span
          className={elemIndex < index ? "opacity-1" : "opacity-0"}
          key={elemIndex}
          style={{
            fontWeight: 800,
            fontSize: "clamp(1rem, 2.5vw, 2rem)",
            wordSpacing: "1px",
            whiteSpace: "normal",
          }}
        >
          {elem}
        </span>
      ))}
    </div>
  )
}

export default TypedText
