import React, { FunctionComponent, useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { StaticImage } from "gatsby-plugin-image"
import { Link, navigate, graphql } from "gatsby"
import update from "immutability-helper"
// @ts-ignore
import addToMailchimp from "gatsby-plugin-mailchimp"
import start from "../images/Press Start.gif"

import Button from "../components/Button"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import TypedText from "../components/TypedText"
import OnScreen from "../components/OnScreen"

interface Core {
  buttonText: string
  content: string
  title: string
  type: "CORE"
}

interface Question {
  answers: string[]
  question: string
  type: "QUESTION"
}

// https://www.vectorstock.com/royalty-free-vector/retro-futuristic-neon-grid-background-80s-design-vector-24648117
// https://www.vectorstock.com/royalty-free-vector/old-game-background-classic-arcade-design-vector-22365509

const Quiz: FunctionComponent = ({ data }) => {
  const newData = data.allContentfulQuiz.edges[0].node
  const quizFlow: (Core | Question)[] = newData.quiz
  const [currentAnswerSelection, setCurrentAnswerSelection] = useState("")
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [responses, setResponses] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const [questionIndex, setQuestionIndex] = useState(0)
  const [stage, setStage] = useState<"INTRO" | "QUIZ" | "RESULTS">("INTRO")

  useEffect(() => {
    var referrer = document.referrer
    console.log("referrer url", referrer)
    if (responses[questionIndex]) {
      setCurrentAnswerSelection(responses[questionIndex])
    }
  }, [questionIndex, page])

  function goBack() {
    if (page > 0) {
      if (quizFlow[page - 1].type === "QUESTION") {
        setQuestionIndex(i => i - 1)
      }

      setPage(p => p - 1)
    } else if (page === 0) {
      setStage("INTRO")
    }
  }

  function onNextQuestion(selection?: string) {
    if (quizFlow[page].type === "QUESTION") {
      setQuestionIndex(i => i + 1)

      if (responses[questionIndex]) {
        setResponses(r =>
          update(r, {
            [questionIndex]: {
              $set: selection || currentAnswerSelection,
            },
          })
        )
      } else {
        setResponses(r =>
          update(r, {
            $push: [selection || currentAnswerSelection],
          })
        )
      }
    }

    setCurrentAnswerSelection("")

    if (page === quizFlow.length - 1) {
      setStage("RESULTS")
    } else {
      setPage(p => p + 1)
    }
  }

  async function onSubmit(event: any) {
    event.preventDefault()

    // adding the hidden tag for quiz
    try {
      await addToMailchimp(
        email,
        {
          FNAME: firstName,
          LNAME: lastName,
        },
        "https://mooremomentum.us20.list-manage.com/subscribe/post?u=705d046da14d7bc6e462e6ec4&amp;id=b6d62873ca&amp;f_id=005a4de0f0"
      )

      showResults()
    } catch (err) {
      console.error(err)
    }
  }

  function showResults() {
    let core1 = 0
    let core2 = 0
    let core3 = 0
    let core4 = 0
    let core5 = 0

    for (let i = 0; i < 15; i += 3) {
      let sum = 0

      for (let j = i; j < i + 3; j += 1) {
        if (responses[j] === "A") {
          sum += 2
        } else if (responses[j] === "B") {
          sum += 0
        } else if (responses[j] === "C") {
          sum += 1
        }
      }

      if (i === 0) {
        core1 = sum
      } else if (i === 3) {
        core2 = sum
      } else if (i === 6) {
        core3 = sum
      } else if (i === 9) {
        core4 = sum
      } else if (i === 12) {
        core5 = sum
      }
    }

    navigate(
      `/quiz-results?core_1=${core1}&core_2=${core2}&core_3=${core3}&core_4=${core4}&core_5=${core5}`
    )
  }

  function getQuizWindow() {
    if (stage === "INTRO") {
      return (
        <div className="text-center text-white">
          <div className="gap-2 grid grid-cols-5 mb-4 md:gap-8 md:mb-8 md:px-8">
            <OnScreen
              className="max-w-full mx-auto p-1 relative w-32 sm:p-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-mindset-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-2">
                <StaticImage
                  alt="will moore 5 cores mindset"
                  placeholder="blurred"
                  src="../images/mindset-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="max-w-full mx-auto p-1 relative w-32 sm:p-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-finance-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-2">
                <StaticImage
                  alt="will moore 5 cores career finance"
                  placeholder="blurred"
                  src="../images/finance-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="max-w-full mx-auto p-1 relative w-32 sm:p-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-relationship-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-2">
                <StaticImage
                  alt="will moore 5 cores relationship"
                  placeholder="blurred"
                  src="../images/relationship-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="max-w-full mx-auto p-1 relative w-32 sm:p-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-physical-health-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-2">
                <StaticImage
                  alt="will moore 5 cores physical health"
                  placeholder="blurred"
                  src="../images/physical-health-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="max-w-full mx-auto p-1 relative w-32 sm:p-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-mental-health-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-2">
                <StaticImage
                  alt="will moore 5 cores emotional health"
                  placeholder="blurred"
                  src="../images/emotional-health-icon.png"
                />
              </div>
            </OnScreen>
          </div>

          <h1 className="font-Orbitron mb-2 text-2xl md:mb-8 md:text-4xl">
            What Are My Core Values Quiz:{" "}
          </h1>

          <h3 className="font-Orbitron mb-4 text-lg md:mb-8 md:text-2xl">
            Level Up in the Game of Life{" "}
          </h3>

          <div className="space-y-4 md:text-lg 2xl:text-xl">
            <p>
              <b>**LEVEL 1:</b> Unlock Your <b>Core Strategy:</b>
            </p>
            <p>
              This core values quiz is your launchpad, designed to help you
              identify your current standings in the Five{" "}
              <Link
                to="/5-core-areas-of-life/"
                className="underline link"
                target="_blank"
              >
                Areas of Life{" "}
              </Link>{" "}
              directly tied to happiness and fulfillment.
            </p>

            <p>
              HONESTY is your best strategy here—the more brutally honest you
              are, the more value and clarity you'll unlock.
            </p>
          </div>

          <div className="max-w-md mt-8 mx-auto">
            <button
              className="animate-pulse bg-coral font-Press-Start inline-block py-3 px-12 rounded-full shadow-lg text-white transition-opacity hover:opacity-70"
              type="button"
              onClick={() => setStage("QUIZ")}
            >
              {/* START QUIZ */}
              Ready Player One?
            </button>
          </div>
        </div>
      )
    }

    if (stage === "RESULTS") {
      return (
        <div className="text-center text-white">
          <TypedText
            className="font-Press-Start mb-2 text-center text-white text-xl sm:text-2xl md:text-3xl xl:text-4xl"
            text="READY PLAYER ONE..."
          />

          <p>We promise we WON&apos;T share your email with anyone else.</p>
          <p className="mb-8">
            By providing your email you agree to weekly gamified habit hack
            emails <br /> and product launch updates. OPT OUT at any time.
          </p>

          <form
            action="https://mooremomentum.us20.list-manage.com/subscribe/post"
            method="POST"
            onSubmit={onSubmit}
          >
            <input type="hidden" name="u" value="705d046da14d7bc6e462e6ec4" />
            <input type="hidden" name="id" value="fc1be8fe04" />
            {/* Hidden input for quiz tag */}
            <input type="hidden" name="tags" value="2083205" />
            {/* Hidden input for quiz tag */}

            <div className="mb-8 space-y-8">
              <div className="gap-8 grid sm:grid-cols-2">
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                    id="MERGE1"
                    name="MERGE1"
                    placeholder="First Name"
                    required
                    type="text"
                    value={firstName}
                    onChange={e => setFirstName(e.currentTarget.value)}
                  />
                </div>

                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                    id="MERGE2"
                    name="MERGE2"
                    placeholder="Last Name"
                    required
                    type="text"
                    value={lastName}
                    onChange={e => setLastName(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div>
                <input
                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                  id="MERGE0"
                  name="MERGE0"
                  placeholder="Email Address"
                  required
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.currentTarget.value)}
                />
              </div>
            </div>

            <Button className="text-sm md:text-base" type="submit">
              Show me my CORE score!
            </Button>
          </form>
        </div>
      )
    }

    return quizFlow.map((elem, index) => {
      if (page === index && elem.type === "CORE") {
        return (
          <div className="slide-in-right" key={elem.title}>
            {elem.title.includes("CORE 1") ? (
              <OnScreen
                className="max-w-full mb-8 mx-auto p-1 relative w-32 sm:p-2 md:w-40 lg:w-48"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-mindset-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores mindset"
                    placeholder="blurred"
                    src="../images/mindset-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            {elem.title.includes("CORE 2") ? (
              <OnScreen
                className="max-w-full mb-8 mx-auto p-1 relative w-32 sm:p-2 md:w-40 lg:w-48"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-finance-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores career finance"
                    placeholder="blurred"
                    src="../images/finance-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            {elem.title.includes("CORE 3") ? (
              <OnScreen
                className="max-w-full mb-8 mx-auto p-1 relative w-32 sm:p-2 md:w-40 lg:w-48"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-relationship-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores relationship"
                    placeholder="blurred"
                    src="../images/relationship-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            {elem.title.includes("CORE 4") ? (
              <OnScreen
                className="max-w-full mb-8 mx-auto p-1 relative w-32 sm:p-2 md:w-40 lg:w-48"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-physical-health-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores physical health"
                    placeholder="blurred"
                    src="../images/physical-health-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            {elem.title.includes("CORE 5") ? (
              <OnScreen
                className="max-w-full mb-8 mx-auto p-1 relative w-32 sm:p-2 md:w-40 lg:w-48"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-mental-health-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores emotional health"
                    placeholder="blurred"
                    src="../images/emotional-health-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            <div className="mb-8 text-center text-white">
              <TypedText
                className="font-Press-Start mb-8 text-center text-white text-xl sm:text-2xl md:text-3xl xl:text-4xl"
                loop={false}
                text={elem.title}
              />

              <p className="font-Orbitron md:text-lg">{elem.content}</p>
            </div>

            <div className="gap-4 grid grid-cols-1 max-w-xs mx-auto">
              <Button
                className="bg-blue text-sm w-full md:text-base"
                theme="WHITE"
                type="button"
                onClick={() => onNextQuestion()}
              >
                {elem.buttonText}
              </Button>

              <Button
                className="text-sm w-full md:text-base"
                type="button"
                onClick={() => goBack()}
              >
                Go Back
              </Button>
            </div>
          </div>
        )
      }

      if (page === index && elem.type === "QUESTION") {
        return (
          <div className="slide-in-right" key={elem.question}>
            <p className="font-Orbitron max-w-md mb-4 mx-auto text-lg text-center text-white md:mb-8 md:text-xl lg:max-w-lg lg:text-2xl">
              {elem.question}
            </p>

            <div className="space-y-4 sm:space-y-8">
              <div className="max-w-md mx-auto">
                <button
                  className={clsx(
                    "border-2 p-4 rounded-lg shadow-lg text-left transition-all w-full hover:opacity-70",
                    currentAnswerSelection === "A"
                      ? "bg-green border-green text-white"
                      : "bg-white border-coral"
                  )}
                  type="button"
                  onClick={() => {
                    setCurrentAnswerSelection("A")
                    onNextQuestion("A")
                  }}
                >
                  <p className="text-sm lg:text-base">
                    {`A. ${elem.answers[0]}`}
                  </p>
                </button>
              </div>

              <div className="max-w-md mx-auto">
                <button
                  className={clsx(
                    "border-2 p-4 rounded-lg shadow-lg text-left transition-all w-full hover:opacity-70",
                    currentAnswerSelection === "B"
                      ? "bg-green border-green text-white"
                      : "bg-white border-coral"
                  )}
                  type="button"
                  onClick={() => {
                    setCurrentAnswerSelection("B")
                    onNextQuestion("B")
                  }}
                >
                  <p className="text-sm lg:text-base">
                    {`B. ${elem.answers[1]}`}
                  </p>
                </button>
              </div>

              <div className="max-w-md mx-auto">
                <button
                  className={clsx(
                    "border-2 p-4 rounded-lg shadow-lg text-left transition-all w-full hover:opacity-70",
                    currentAnswerSelection === "C"
                      ? "bg-green border-green text-white"
                      : "bg-white border-coral"
                  )}
                  type="button"
                  onClick={() => {
                    setCurrentAnswerSelection("C")
                    onNextQuestion("C")
                  }}
                >
                  <p className="text-sm lg:text-base">
                    {`C. ${elem.answers[2]}`}
                  </p>
                </button>
              </div>

              <div className="max-w-xs mx-auto">
                <Button
                  className="text-sm w-full md:text-base"
                  type="button"
                  onClick={() => goBack()}
                >
                  Go Back
                </Button>
              </div>
            </div>
          </div>
        )
      }

      return null
    })
  }

  return (
    <Layout showQuizPopup={false}>
      <SEO
        description="Take the Core Values Quiz to determine where you stand in the most important areas of life, and the first momentum building step to level up in all 5!"
        title="Core Values Quiz"
        link="https://mooremomentum.com/core-values-quiz/"
      />

      <div className="mt-8 mx-auto px-4 relative z-10 md:max-w-full xl:max-w-5xl">
        <div className="bg-black p-4 relative rounded-md shadow-lg quiz-box">
          <div className="relative">
            <div className="absolute bg-black bg-opacity-60 inset-0 z-10" />

            <div className="hidden sm:block">
              <StaticImage
                alt="Super Mario Level"
                placeholder="blurred"
                objectPosition="bottom"
                src="../images/Emmett-Happy-Town-V2.png"
                style={{ maxHeight: "60vh" }}
              />
            </div>

            <div className="block sm:hidden">
              <StaticImage
                alt="Super Mario Level"
                placeholder="blurred"
                src="../images/quiz-bg-2-mobile.png"
              />
            </div>

            {page < 4 && quizFlow[page].type === "QUESTION" ? (
              <OnScreen
                className="absolute left-4 mb-8 mx-auto p-1 top-4 w-16 z-20 sm:w-20 lg:p-2 lg:w-32"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-mindset-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores mindset"
                    placeholder="blurred"
                    src="../images/mindset-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            {page > 4 && page < 8 && quizFlow[page].type === "QUESTION" ? (
              <OnScreen
                className="absolute left-4 mb-8 mx-auto p-1 top-4 w-16 z-20 sm:w-20 lg:p-2 lg:w-32"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-finance-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores career finance"
                    placeholder="blurred"
                    src="../images/finance-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            {page > 8 && page < 12 && quizFlow[page].type === "QUESTION" ? (
              <OnScreen
                className="absolute left-4 mb-8 mx-auto p-1 top-4 w-16 z-20 sm:w-20 lg:p-2 lg:w-32"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-relationship-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores relationship"
                    placeholder="blurred"
                    src="../images/relationship-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            {page > 12 && page < 16 && quizFlow[page].type === "QUESTION" ? (
              <OnScreen
                className="absolute left-4 mb-8 mx-auto p-1 top-4 w-16 z-20 sm:w-20 lg:p-2 lg:w-32"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-physical-health-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores physical health"
                    placeholder="blurred"
                    src="../images/physical-health-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}

            {page > 16 &&
            page < 20 &&
            quizFlow[page].type === "QUESTION" &&
            stage === "QUIZ" ? (
              <OnScreen
                className="absolute left-4 mb-8 mx-auto p-1 top-4 w-16 z-20 sm:w-20 lg:p-2 lg:w-32"
                classToAdd="scale-in-center"
              >
                <div className="absolute core-mental-health-gradient inset-0 rounded-full shadow-lg spin" />

                <div className="core-gradient relative rounded-full shadow-lg p-2">
                  <StaticImage
                    alt="will moore 5 cores emotional health"
                    placeholder="blurred"
                    src="../images/emotional-health-icon.png"
                  />
                </div>
              </OnScreen>
            ) : null}
          </div>

          <div className="absolute flex items-center inset-0 justify-center overflow-hidden p-8 z-20">
            {getQuizWindow()}

            <div
              className="absolute bg-blue bottom-0 h-4 left-0 transition-all"
              style={{ right: `${100 - (page / quizFlow.length) * 100}%` }}
            />
          </div>
        </div>
      </div>

      <div className="md:pb-8 pb=0 px-4">
        <svg
          className="mx-auto -mt-64 w-full 2xl:-mt-48"
          width="482"
          height="520"
          viewBox="0 0 482 520"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.78205 312.461H446.218C447.202 312.461 448 313.259 448 314.243V501.32C448 502.304 447.202 503.102 446.218 503.102H4.78205C3.79785 503.102 3 502.304 3 501.32V314.243C3 313.259 3.79785 312.461 4.78205 312.461Z"
            fill="url(#paint0_linear_44_237)"
          />
          <path
            d="M4.78205 312.461H446.218C447.202 312.461 448 313.259 448 314.243V501.32C448 502.304 447.202 503.102 446.218 503.102H4.78205C3.79785 503.102 3 502.304 3 501.32V314.243C3 313.259 3.79785 312.461 4.78205 312.461Z"
            fill="url(#paint1_linear_44_237)"
            fillOpacity="0.2"
          />
          <path
            d="M4.78205 312.461H446.218C447.202 312.461 448 313.259 448 314.243V501.32C448 502.304 447.202 503.102 446.218 503.102H4.78205C3.79785 503.102 3 502.304 3 501.32V314.243C3 313.259 3.79785 312.461 4.78205 312.461Z"
            stroke="black"
            strokeWidth="3"
          />
          <path
            d="M16.2692 338.858C16.2692 337.952 17.0039 337.217 17.9103 337.217H142.628V488.191H17.9103C17.0039 488.191 16.2692 487.457 16.2692 486.55V338.858Z"
            fill="#1A1A1A"
          />
          <path
            d="M275.551 337.217H431.449C432.355 337.217 433.09 337.952 433.09 338.858V486.55C433.09 487.457 432.355 488.191 431.449 488.191H275.551V337.217Z"
            fill="#1A1A1A"
          />
          <rect
            x="142.628"
            y="468.499"
            width="132.923"
            height="8.20513"
            fill="#1A1A1A"
          />
          <rect
            x="142.628"
            y="414.345"
            width="132.923"
            height="8.20513"
            fill="#1A1A1A"
          />
          <rect
            x="142.628"
            y="383.166"
            width="132.923"
            height="8.20513"
            fill="#1A1A1A"
          />
          <rect
            x="142.628"
            y="351.986"
            width="132.923"
            height="8.20513"
            fill="#1A1A1A"
          />
          <path
            d="M142.628 337.217H275.551V351.986C275.551 353.799 274.082 355.268 272.269 355.268H145.91C144.098 355.268 142.628 353.799 142.628 351.986V337.217Z"
            fill="#999999"
          />
          <path
            d="M142.628 476.704C142.628 474.892 144.098 473.422 145.91 473.422H272.269C274.082 473.422 275.551 474.892 275.551 476.704V488.191H142.628V476.704Z"
            fill="#999999"
          />
          <rect
            x="142.628"
            y="360.191"
            width="132.923"
            height="24.6154"
            fill="#999999"
          />
          <rect
            x="142.628"
            y="389.73"
            width="132.923"
            height="24.6154"
            fill="#999999"
          />
          <rect
            x="63.0385"
            y="368.397"
            width="32.8205"
            height="93.5385"
            rx="1.5"
            fill="#898989"
          />
          <rect
            x="32.6794"
            y="431.576"
            width="32.8205"
            height="93.5385"
            rx="1.5"
            transform="rotate(-90 32.6794 431.576)"
            fill="#898989"
          />
          <rect x="61" y="397" width="33" height="33" fill="black" />
          <rect
            x="142.628"
            y="422.55"
            width="132.923"
            height="45.9487"
            fill="#D4D4D4"
          />
          <rect
            x="146.321"
            y="426.243"
            width="123.897"
            height="36.9231"
            rx="1.23077"
            fill="#C4C4C4"
            stroke="#A7A7A7"
            strokeWidth="0.820513"
          />
          <rect
            x="213.192"
            y="440.602"
            width="52.5128"
            height="13.1282"
            rx="1.64103"
            fill="black"
            fillOpacity="0.8"
          />
          <rect
            x="154.115"
            y="440.602"
            width="52.5128"
            height="13.1282"
            rx="1.64103"
            fill="black"
            fillOpacity="0.8"
          />
          <rect
            x="152.474"
            y="438.961"
            width="52.5128"
            height="13.1282"
            rx="1.64103"
            fill="black"
          />
          <rect
            x="211.551"
            y="438.961"
            width="52.5128"
            height="13.1282"
            rx="1.64103"
            fill="black"
          />
          <rect
            x="299"
            y="412.345"
            width="50.8718"
            height="49.2308"
            rx="1.64103"
            fill="#CBCBCB"
          />
          <rect
            x="358.077"
            y="412.345"
            width="50.8718"
            height="49.2308"
            rx="1.64103"
            fill="#CBCBCB"
          />
          <rect
            x="328.538"
            y="355"
            width="50.8718"
            height="49.2308"
            rx="1.64103"
            fill="#CBCBCB"
          />

          {/* B bottom layer */}
          <circle
            cx="325.256"
            cy="438.602"
            r="21.3333"
            fill="#EA3223"
            stroke="#000000"
            strokeWidth="1.64103"
          />

          {/* C bottom layer */}
          <circle
            cx="384.333"
            cy="438.602"
            r="21.3333"
            fill="#EA3223"
            stroke="#000000"
            strokeWidth="1.64103"
          />

          {/* A bottom layer */}
          <circle
            cx="354.795"
            cy="381.256"
            r="21.3333"
            fill="#EA3223"
            stroke="#000000"
            strokeWidth="1.64103"
          />

          {/* A top layer */}
          <g
            className="animate-pulse controller-button"
            role="button"
            onClick={() => setCurrentAnswerSelection("A")}
          >
            <circle
              className="transition-opacity hover:opacity-70"
              cx="350.795"
              cy="377.256"
              r="21.3333"
              fill="#EA3223"
              stroke="#BC261A"
              strokeWidth="1.64103"
            />

            <g filter="url(#filter0_i_44_237)">
              <path
                d="M344.45 384.846V373.405C344.45 372.657 344.719 372.013 345.257 371.475C345.796 370.937 346.439 370.668 347.187 370.668H355.871C356.633 370.668 357.283 370.937 357.821 371.475C358.359 372.013 358.628 372.657 358.628 373.405V384.846H356.009V380.081H347.049V384.846H344.45ZM347.049 377.481H356.009V373.976C356.009 373.608 355.983 373.405 355.93 373.366C355.878 373.313 355.674 373.287 355.32 373.287H347.738C347.371 373.287 347.161 373.313 347.108 373.366C347.069 373.405 347.049 373.608 347.049 373.976V377.481Z"
                fill="white"
              />
            </g>
          </g>

          {/* B bottom layer */}
          <g
            className="animate-pulse controller-button"
            role="button"
            onClick={() => setCurrentAnswerSelection("B")}
          >
            <circle
              className="transition-opacity hover:opacity-70"
              cx="321.256"
              cy="434.602"
              r="21.3333"
              fill="#EA3223"
              stroke="#BC261A"
              strokeWidth="1.64103"
            />

            <g filter="url(#filter1_i_44_237)">
              <path
                d="M314.931 442.191V428.013H325.801C326.55 428.013 327.193 428.282 327.731 428.82C328.269 429.359 328.538 430.002 328.538 430.75V433.527C328.538 433.697 328.525 433.862 328.499 434.019C328.473 434.177 328.427 434.328 328.361 434.472C328.584 434.774 328.762 435.089 328.893 435.417C329.037 435.745 329.11 436.074 329.11 436.402V439.454C329.11 440.202 328.84 440.846 328.302 441.384C327.764 441.922 327.114 442.191 326.353 442.191H314.931ZM318.22 439.572H325.801C326.156 439.572 326.359 439.553 326.412 439.513C326.464 439.461 326.49 439.251 326.49 438.883V436.973C326.49 436.605 326.464 436.402 326.412 436.362C326.359 436.31 326.156 436.284 325.801 436.284H318.22C317.852 436.284 317.642 436.31 317.59 436.362C317.55 436.402 317.53 436.605 317.53 436.973V438.883C317.53 439.251 317.55 439.461 317.59 439.513C317.642 439.553 317.852 439.572 318.22 439.572ZM318.22 433.665H325.23C325.585 433.665 325.782 433.645 325.821 433.606C325.873 433.553 325.9 433.343 325.9 432.975V431.321C325.9 430.954 325.873 430.75 325.821 430.711C325.782 430.658 325.585 430.632 325.23 430.632H318.22C317.852 430.632 317.642 430.658 317.59 430.711C317.55 430.75 317.53 430.954 317.53 431.321V432.975C317.53 433.343 317.55 433.553 317.59 433.606C317.642 433.645 317.852 433.665 318.22 433.665Z"
                fill="white"
              />
            </g>
          </g>

          {/* C bottom layer */}
          <g
            className="animate-pulse controller-button"
            role="button"
            onClick={() => setCurrentAnswerSelection("C")}
          >
            <circle
              className="transition-opacity hover:opacity-70"
              cx="380.333"
              cy="434.602"
              r="21.3333"
              fill="#EA3223"
              stroke="#BC261A"
              strokeWidth="1.64103"
            />

            <g filter="url(#filter2_i_44_237)">
              <path
                d="M376.686 442.191C375.938 442.191 375.295 441.922 374.756 441.384C374.218 440.846 373.949 440.202 373.949 439.454V430.75C373.949 430.002 374.218 429.359 374.756 428.82C375.295 428.282 375.938 428.013 376.686 428.013H388.088V430.632H377.238C376.87 430.632 376.66 430.658 376.607 430.711C376.568 430.75 376.548 430.954 376.548 431.321V438.883C376.548 439.251 376.568 439.461 376.607 439.513C376.66 439.553 376.87 439.572 377.238 439.572H388.088V442.191H376.686Z"
                fill="white"
              />
            </g>
          </g>

          <path
            d="M238.398 313.154C247.319 211.14 177.14 69.6378 105.177 187.282C33.2137 304.927 -55.402 188.928 60.5716 3"
            stroke="black"
            strokeWidth="8.20513"
          />
          <path
            d="M480.014 332.709L449.519 314.412C448.852 314.011 448.003 314.493 448.005 315.272L448.497 501.497C448.499 502.051 448.051 502.5 447.497 502.5H5.25287C4.14942 502.5 3.85918 504.025 4.88545 504.43L40.3229 518.43C40.4399 518.476 40.5646 518.5 40.6904 518.5H475.337C475.445 518.5 475.553 518.482 475.655 518.447C478.79 517.367 479.963 515.941 480.5 511.5V333.566C480.5 333.215 480.316 332.889 480.014 332.709Z"
            fill="url(#paint2_linear_44_237)"
          />
          <path
            d="M480.014 332.709L449.519 314.412C448.852 314.011 448.003 314.493 448.005 315.272L448.497 501.497C448.499 502.051 448.051 502.5 447.497 502.5H5.25287C4.14942 502.5 3.85918 504.025 4.88545 504.43L40.3229 518.43C40.4399 518.476 40.5646 518.5 40.6904 518.5H475.337C475.445 518.5 475.553 518.482 475.655 518.447C478.79 517.367 479.963 515.941 480.5 511.5V333.566C480.5 333.215 480.316 332.889 480.014 332.709Z"
            fill="url(#paint3_linear_44_237)"
          />
          <path
            d="M480.014 332.709L449.519 314.412C448.852 314.011 448.003 314.493 448.005 315.272L448.497 501.497C448.499 502.051 448.051 502.5 447.497 502.5H5.25287C4.14942 502.5 3.85918 504.025 4.88545 504.43L40.3229 518.43C40.4399 518.476 40.5646 518.5 40.6904 518.5H475.337C475.445 518.5 475.553 518.482 475.655 518.447C478.79 517.367 479.963 515.941 480.5 511.5V333.566C480.5 333.215 480.316 332.889 480.014 332.709Z"
            stroke="black"
            strokeWidth="3"
          />
          <path
            d="M61 368.5C61 367.672 61.6716 367 62.5 367H92.5C93.3284 367 94 367.672 94 368.5V397H61V368.5Z"
            fill="black"
          />
          <path
            d="M78.134 376.5C78.5189 375.833 79.4811 375.833 79.866 376.5L84.1962 384C84.5811 384.667 84.0999 385.5 83.3301 385.5H74.6699C73.9001 385.5 73.4189 384.667 73.8038 384L78.134 376.5Z"
            fill="#555555"
            fillOpacity="0.7"
          />
          <path
            d="M61 430H94V459.5C94 460.328 93.3284 461 92.5 461H62.5C61.6716 461 61 460.328 61 459.5V430Z"
            fill="black"
          />
          <path
            d="M78.134 450.5C78.5189 451.167 79.4811 451.167 79.866 450.5L84.1962 443C84.5811 442.333 84.0999 441.5 83.3301 441.5H74.6699C73.9001 441.5 73.4189 442.333 73.8038 443L78.134 450.5Z"
            fill="#555555"
            fillOpacity="0.7"
          />
          <path
            d="M94 430V397H123.5C124.328 397 125 397.672 125 398.5V428.5C125 429.328 124.328 430 123.5 430H94Z"
            fill="black"
          />
          <path
            d="M114.5 413.866C115.167 413.481 115.167 412.519 114.5 412.134L107 407.804C106.333 407.419 105.5 407.9 105.5 408.67V417.33C105.5 418.1 106.333 418.581 107 418.196L114.5 413.866Z"
            fill="#555555"
            fillOpacity="0.7"
          />
          <path
            d="M32.5 430C31.6716 430 31 429.328 31 428.5L31 398.5C31 397.672 31.6716 397 32.5 397H61L61 430H32.5Z"
            fill="black"
          />
          <path
            d="M40.5 413.866C39.8333 413.481 39.8333 412.519 40.5 412.134L48 407.804C48.6667 407.419 49.5 407.9 49.5 408.67V417.33C49.5 418.1 48.6667 418.581 48 418.196L40.5 413.866Z"
            fill="#555555"
            fillOpacity="0.7"
          />
          <defs>
            <filter
              id="filter0_i_44_237"
              x="344.45"
              y="370.668"
              width="14.1785"
              height="20.7426"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6.5641" />
              <feGaussianBlur stdDeviation="3.28205" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_44_237"
              />
            </filter>
            <filter
              id="filter1_i_44_237"
              x="314.931"
              y="428.013"
              width="14.1785"
              height="20.7426"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6.5641" />
              <feGaussianBlur stdDeviation="3.28205" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_44_237"
              />
            </filter>
            <filter
              id="filter2_i_44_237"
              x="373.949"
              y="428.013"
              width="14.1391"
              height="20.7426"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="6.5641" />
              <feGaussianBlur stdDeviation="3.28205" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_44_237"
              />
            </filter>
            <linearGradient
              id="paint0_linear_44_237"
              x1="3.96154"
              y1="314.243"
              x2="447.859"
              y2="501.32"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ADADAD" />
              <stop offset="1" stopColor="#EFEFEF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_44_237"
              x1="468.372"
              y1="324.909"
              x2="466.731"
              y2="507.063"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A0A0A0" />
              <stop offset="1" stopColor="#A0A0A0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_44_237"
              x1="8"
              y1="504.5"
              x2="483.5"
              y2="508"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D7D7D7" />
              <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_44_237"
              x1="467"
              y1="328"
              x2="448.5"
              y2="519"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#A0A0A0" />
              <stop offset="1" stopColor="#A8A8A8" stopOpacity="0.09" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="container md:mt-12 mt-0 mx-auto px-4 mb-12 pb-8">
        <h2 className="h2">
          Mission Briefing: What This Core Values Quiz Unlocks
        </h2>
        <br />
        <p>
          Welcome, player, to the ultimate journey of self-discovery and mastery
          in the{" "}
          <Link to="/gamify-your-life/" className="link" target="_blank">
            game of life
          </Link>
          . With endless paths and challenges ahead, your core values serve as
          your true north star, guiding you through life's challenges and{" "}
          <Link
            to="https://mooremomentum.com/blog/overcome-barriers-to-effective-decision-making-using-powerful-habits/"
            className="link"
            target="_blank"
          >
            decisions{" "}
          </Link>{" "}
          with clarity and confidence.{" "}
        </p>
        <br />

        <p>
          This personal values assessment isn't just a one-off time waster, but
          instead the beginning of a purposeful quest to harness your inner
          strengths, passions, and personal values, setting the stage for a life
          rich with achievement and satisfaction.
        </p>
        <br />
        <p className="bold">
          <b>Your Quest Objectives:</b>
        </p>
        <br />
        <ul className="list">
          <li>
            <b>Learn Life’s Key Areas:</b> Knowledge is power. Discover the 5
            Core Areas of Life scientifically correlated to your happiness.
          </li>
          <li>
            <b>Become Aware of Where You Stand:</b> Get a baseline for the{" "}
            <Link
              to="https://mooremomentum.com/blog/10-easy-ways-to-indentitify-your-personal-core-values/"
              className="link"
              target="_blank"
            >
              personal core values
            </Link>{" "}
            currently propelling your forward, and which ones you'll want to
            address ASAP in all 5 Cores.
          </li>
          <li>
            <b>Launch to Level 2:</b> Post-quiz, get a sneak peek at the next
            level, where gamification turns the journey of self-improvement into
            an adventure you're eager to continue.
          </li>
        </ul>
        <br />
        <h2 className="h2">The Power of Knowing Your Core Values</h2>
        <br />
        <p>
          Discovering your core values is akin to unlocking a master key in the
          vast game of life. It equips you with the insight and foresight to
          navigate life's levels strategically, ensuring every decision, every
          challenge, and every triumph resonates deeply and meaningfully with
          your true self.
        </p>
        <br />
        <h3 className="h3">
          Power-Ups Gained From Understanding Your Core Values:
        </h3>
        <br />
        <ul className="list">
          <li>
            <b>Clarity and Focus:</b> Cut through the noise of daily
            distractions and societal pressures, honing in on what truly matters
            to you.
          </li>
          <li>
            <b> Enhanced Decision-Making:</b> Stand at life's crossroads with
            confidence, making choices that resonate with your deepest beliefs.
          </li>
          <li>
            <b>Resilience and Stability:</b> Ground yourself with unwavering
            principles that offer support and direction, even amidst turmoil.
          </li>
          <li>
            <b>Personal Growth:</b> Embark on a journey of self-direction that
            is meaningful, directed, and infinitely rewarding.
          </li>
          <li>
            <b>Balanced Life:</b> Achieve harmony across the essential areas of
            life, fostering holistic well-being and happiness.
          </li>
        </ul>
        <br />
        <h2 className="h2">
          How Your Core Values Influence Your 5 Core Areas Of Life
        </h2>
        <br />
        <p>
          Understanding how your personal values interact with the Five Core
          Areas is crucial to mastering the game of life. By identifying and
          enhancing these distinct values, you effectively upgrade your belief
          system, which in turn influences the actions you take daily. This
          strategic alignment is key to replacing 'failure habits' with '
          <Link
            to="https://mooremomentum.com/success-habits/"
            className="link"
            target="_blank"
          >
            success habits
          </Link>
          ' across all areas and{" "}
          <Link
            to="https://mooremomentum.com/blog/how-to-accomplish-your-goals/"
            className="link"
            target="_blank"
          >
            accomplishing your goals
          </Link>
          .
        </p>
        <br />
        <p>
          This all ties into the <b>"Equation Of Life:"</b>
        </p>
        <br />
        <div className="m-auto flex">
          <StaticImage
            src="../images/equationoflife.png"
            alt="Equation of Life"
            style={{ margin: "0 auto", display: "felx" }}
            imgStyle={{ margin: "0 auto" }}
          />
        </div>
        <br />
        <h3 className="h3">5 Core Areas of Life:</h3>
        <br />
        <ul className="list">
          <li>
            <b>Mindset:</b> Explore the environment of your thoughts and
            attitudes–Are they fostering growth or holding you back?
          </li>
          <li>
            <b>Career and Finances:</b> Gauge your professional fulfillment and
            financial stability—are they aligned with your ultimate quest?
          </li>
          <li>
            <b>Relationships:</b> Evaluate your alliances—are they strengthening
            or hindering your journey?
          </li>
          <li>
            <b>Physical Health:</b> Take stock of your body's well-being–is it
            being nurtured or neglected?
          </li>
          <li>
            <b>Emotional & Mental Health:</b> Understand your emotional and
            mental landscape—how well are you navigating its complexities?
          </li>
        </ul>
        <br />
        <p>
          Your core values are more than just personality traits; they're your
          cheat codes to unlock levels of fulfillment, clarity, and resilience
          beyond your imagination.{" "}
          <a
            href="https://hbr.org/2023/02/how-to-find-define-and-use-your-values"
            className="link"
            target="_blank"
          >
            Experts
          </a>{" "}
          suggest that understanding personal values is crucial as they help
          make decisions, guide careers, and lead to a happier life.
        </p>
        <br></br>
        <p>
          Shining a spotlight on what matters most paves the way for upgrading
          your belief system, behavior and actions, setting you on a path to
          conquer all Five Cores.
        </p>
        <br />
        <p>
          As you build momentum, you'll launch towards discovering all that life
          has to offer, fully prepared to implement the{" "}
          <Link
            to="https://mooremomentum.com/"
            target="_blank"
            className="link"
          >
            Moore Momentum System{" "}
          </Link>
          for a personalized journey of growth, development, and discovery.
        </p>
        <br />
        <h2 className="h2">*Launch Sequence Activated</h2>
        <br />
        <p>
          Take the{" "}
          <Link to="/core-values-quiz/" target="_blank" className="link">
            <b> FREE QUIZ NOW!</b>
          </Link>
        </p>
        <br />
        <p>
          Initiate your ascent by taking the free quiz now. Gain that crucial
          burst of momentum needed to break free from gravity's pull and embark
          on your epic journey to uncover all the universe has to offer.
        </p>
        <br />
        <p className="bold">Ready Player One? Your adventure starts now.</p>
        <br />
        <div className="flex items-center justify-center">
          <Link to="/core-values-quiz/">
            <img
              alt="press start button animation"
              src={start}
              className="m-auto"
              onClick={() => {
                setStage("QUIZ")
              }}
            />
          </Link>
        </div>
        <br />
        <h3 className="h3">FAQs: What Are My Core Values Quiz</h3>
        <br />
        <p className="faq">1. What is a Core Values Quiz?</p>
        <br />
        <p>
          This personal values quiz is an assessment tool designed to help
          individuals understand how their personal values influence their world
          in order to determine the changes needed to create a new one.
        </p>
        <br />
        <p className="faq">
          2. How does understanding my personal values benefit me?
        </p>
        <br />
        <p>
          Understanding your personal values offers numerous benefits, including
          improved decision-making, enhanced personal growth, and greater
          resilience and stability. It helps you navigate life's challenges with
          confidence, ensuring your actions and choices are aligned with your
          deepest beliefs and core values.
        </p>
        <br />
        <p className="faq">3. What areas of life does the quiz cover?</p>
        <br />
        <p>
          The quiz explores the Five Core Areas of Life: 1) Mindset, 2) Career
          and Finances, 3) Relationships, 4) Physical Health, and 5) Emotional &
          Mental Health. It provides insights into how your core values impact
          each of these areas, helping you discover your genuine motivations and
          guiding principles.
        </p>
        <br />
        <p className="faq">4. How long does the quiz take to complete?</p>
        <br />
        <p>
          The quiz is designed to be both thorough and efficient, requiring only
          a few minutes to complete. We value your time and aim to provide
          meaningful insights without taking up too much of it.
        </p>
        <br />
        <p className="faq">5. How can I use my quiz results?</p>
        <br />
        <p>
          Your quiz results offer a <b> personal values assessment </b>that
          highlights your strongest <b>core values</b>. Use these insights to
          move to "Level 2" where you'll gain an even deeper understanding of
          the pain points holding you back, and the gamified methods to overcome
          them.
        </p>
        <br />
        <p className="faq">
          6. Can this Core Values Index help with career decisions?
        </p>
        <br />
        <p>
          Absolutely. By clarifying your <b>core values</b>, the quiz will act
          as a values guide to making decisions that align with your passions,
          purpose, and long-term goals.
        </p>
        <br />
        <p className="faq">
          7. Will taking the quiz improve my relationships?{" "}
        </p>
        <br />
        <p>
          Yes, understanding your <b>core values</b> can significantly enhance
          your <b>relationships</b> by helping you communicate your needs and
          expectations more clearly, fostering deeper connections, and aligning
          your social interactions with your values.
        </p>
        <br />
        <p className="faq">
          8. What if I'm unsure about my answers to some of the questions?{" "}
        </p>
        <br />
        <p>
          It's natural to feel uncertain at times. We encourage you to answer as
          honestly as possible but remember, the journey of{" "}
          <b>personal development</b>
          is ongoing. Your <b>core values</b> may evolve, and you can retake the
          free quiz anytime to reflect these changes.
        </p>
        <br />
        <p className="faq">
          9. Where can I find more resources on personal development and living
          according to my core values?{" "}
        </p>
        <br />
        <p>
          After completing the quiz, we invite you to explore our free E-book
          with some starter tools to laser focus your personal development and
          self-direction, This will help you with those initial important
          decisions that align with your values.
        </p>
        <OnScreen classToAdd="slide-in-right">
          <div className="container mt-20 mb-10 mx-auto px-10 md:mb-24">
            <div className="grid rounded-md shadow-lg">
              <StaticImage
                alt="App Background Screen"
                className="space-bg rounded-md"
                layout="fullWidth"
                objectPosition="57% 57%"
                placeholder="blurred"
                quality={100}
                src="../images/index-app-bg.png"
                style={{ gridArea: "1/1" }}
              />

              <div className="grid relative" style={{ gridArea: "1/1" }}>
                <div className="absolute bg-black bg-opacity-30 inset-0 rounded-md" />

                <div className="absolute bottom-0 hidden left-0 z-0 md:block md:w-36 xl:w-full">
                  <StaticImage
                    alt="Alien"
                    className="w-32"
                    placeholder="blurred"
                    src="../images/alien.png"
                  />
                </div>

                <div className="gap-16 grid grid-cols-1 pt-8 px-8 text-white z-10 md:gap-4 md:grid-cols-2 md:pt-0 md:px-0">
                  <div className="max-w-md mx-auto space-y-8 z-10 md:max-w-lg md:pl-8 md:pt-8 2xl:self-center">
                    <h2 className="font-Orbitron text-2xl lg:text-4xl">
                      Learn more about the Gamify Your Life App
                    </h2>
                    <p>
                      {/* Be the Pilot of your own rocket ship and learn how to fire
                      on all cylinders. Click below to learn more and be the
                      first to know when our app is released! */}
                      Discover the gamified app to propel your momentum -
                      combining behavioral science and universal principles with
                      the latest in technology.
                    </p>{" "}
                    <p>
                      {" "}
                      Have fun and get ethically addicted as you put your skill
                      sets to the test on your mission to save yourself in order
                      to save the world!" Ready to Gamify Your Life?
                    </p>
                    <Button
                      href="https://mooremomentum.com/weekly-habit-tracker-app/"
                      link="GATSBY"
                    >
                      Learn More
                    </Button>
                  </div>

                  <div className="flex items-end justify-center sm:max-w-xs sm:mx-auto">
                    <StaticImage
                      alt="Iphone App Splash Screen"
                      placeholder="blurred"
                      src="../images/app-mock-index.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OnScreen>
        <p>
          Ready to discover the core values that define you? Take the test and
          the quiz today and step into a life where every decision, every
          action, and every moment is infused with purpose and meaning.
        </p>
        <br />
        <p>Let's make change fun, one insightful discovery at a time.</p>
      </div>
    </Layout>
  )
}

export const data = graphql`
  query Quiz {
    allContentfulQuiz {
      edges {
        node {
          quiz {
            content
            answers
            buttonText
            question
            type
            title
          }
        }
      }
    }
  }
`

export default Quiz
